/*
 * Third party
 */


/*
 * Custom
 */
(function($) { $(function() {

    $(".app-form").submit(function () { 
        var th = $(this);
        var form = $(".app-form");
        var myModal = document.getElementById('callback');
        var success = new bootstrap.Modal(document.getElementById('success'));
        $.ajax({
            type: "POST",
            url: "mail.php", //Change
            data: th.serialize()
        }).done(function () {
            //myModal.hide();
            //form.reset();
            $('input').val('');
            $("#callback.show").modal('hide');            
            success.show();
            e.stopPropagation();          
        });
        return false;
    });

}) })(jQuery)